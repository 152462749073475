import { Button, ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ScrollHeaderType } from "../constants/enums";
import { store } from "../modules";
import { setPackageExpiredInfo } from "../modules/session/session.actions";
import PackageExpiredDialog from "../shared/components/package-expired-dialog/package-expired-dialog.component";
import DefaultLogo from "./assets/images/pho-viet-logo.png";
import MetaInfo from "./components/MetaInfo";
import { AddToCartToastMessage } from "./components/add-update-to-cart-toast-message/add-to-cart-toast-message.component";
import { UpdateToCartToastMessage } from "./components/add-update-to-cart-toast-message/update-to-cart-toast.component";
import { ThemeOriginalFooter } from "./components/footer/footer.component";
import Theme2OriginalHeader from "./components/header.component";
import NotificationContainer from "./components/notification-container/notification-container.component";
import ToastMessageContainer from "./components/toast-message-container/toast-message-container";
import { theme2ElementCustomize } from "./constants/store-web-page.constants";
import defaultConfig from "./default-store.config";
import "./index.scss";
import { getRouteMetaInfo, themeToken } from "./theme.data";
import { useMediaQuery } from "react-responsive";
import reviewDataService from "../data-services/review-data.service";
import { StarIcon } from "./assets/icons.constants";
import { useTranslation } from "react-i18next";
import FnbReviewOfBranchModal from "./components/fnb-review-of-branch-modal/fnb-review-of-branch-modal";

function Index(props) {
  const themeConfigReduxState = useSelector((state) => state?.session?.themeConfig);
  const themeConfigMenu = useSelector((state) => state.session?.themeConfigMenu);
  const {
    contentPage: ContentComponent,
    clickToFocusCustomize,
    isDefault,
    isCustomize,
    fontFamily = themeToken.fontFamily,
  } = props;
  const [_fontFamily, setFontFamily] = useState(fontFamily);
  const [currentPageConfig, setCurrentPageConfig] = useState(null);
  const [colorFooterGeneral, setColorFooterGeneral] = useState();
  const logoDefault = {
    url: DefaultLogo,
    alt: "Logo",
  };
  const defaultThemePageConfig = defaultConfig;
  const isMobileDevice = useMediaQuery({ maxWidth: 575 });
  const branchAddress = useSelector((state) => state.session?.deliveryAddress?.branchAddress);
  const [overallRating, setOverallRating] = useState();
  const [totalReview, setTotalReview] = useState();
  const [isShowOverallReview, setIsShowOverallReview] = useState(false);
  const [overAllData, setOverAllData] = useState();
  const [t] = useTranslation();

  useEffect(() => {
    getPageConfig();
    getOverallReview();
  }, []);

  useEffect(() => {
    let config = themeConfigReduxState;

    let currentColor = config?.general?.color?.colorGroups?.find(
      (x) => x.id === config?.general?.footer?.generalCustomization?.colorGroupId,
    );
    setColorFooterGeneral(currentColor);
    getPageConfig();

    const fontCustomized = config?.general?.font?.name;
    if (Boolean(isCustomize) && Boolean(fontCustomized)) {
      setFontFamily(fontCustomized);
    }

    store?.dispatch(setPackageExpiredInfo(null));
  }, [themeConfigReduxState]);

  // Init scroll handler
  useEffect(() => {
    let prevScrollPos = 0;
    const handleScroll = () => {
      // Handle scroll header & category product list theme2
      if (themeConfigReduxState?.general?.header?.scrollType === ScrollHeaderType.FIXED) return;
      const currentScrollPos = window.scrollY;
      const headerThemeElement = document.getElementById("header-theme2");
      const heighHeader = headerThemeElement?.offsetHeight ?? 0;
      const elementCategoryProductList = document.getElementById("nav-category-sticky");
      const modeViewProductListElement = document.getElementById("mode-view-product-list");
      const heightCategories = elementCategoryProductList?.offsetHeight ?? 0;
      if (prevScrollPos > currentScrollPos) {
        // Scrolling up, show the header
        if (headerThemeElement) headerThemeElement.style.top = "0";
        // Categories
        if (elementCategoryProductList) {
          elementCategoryProductList.style.top = `${heighHeader}px`;
        }
        // Mode view product list
        if (isMobileDevice && modeViewProductListElement) {
          modeViewProductListElement.style.top = `${heighHeader + heightCategories}px`;
        }
      } else {
        // Scrolling down, hide the header
        if (headerThemeElement) headerThemeElement.style.top = "-100px";
        if (elementCategoryProductList) {
          elementCategoryProductList.style.top = "0";
        }
        if (isMobileDevice && modeViewProductListElement) {
          modeViewProductListElement.style.top = `${heightCategories}px`;
        }
      }
      if (currentScrollPos <= 0 || prevScrollPos <= 0) {
        if (headerThemeElement) headerThemeElement.style.top = "0";
      }
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const translatedData = {
    review: t("review.review"),
  };

  const getPageConfig = () => {
    const { pageId } = props;

    if (!themeConfigReduxState?.pages) return;

    let pageConfig = themeConfigReduxState?.pages.find((p) => p.id === pageId);
    if (pageConfig) {
      const generalConfig = themeConfigReduxState?.general;
      if (
        generalConfig &&
        generalConfig.color &&
        generalConfig.color.colorGroups &&
        generalConfig.color.colorGroups.length > 0 &&
        pageConfig.config.colorGroupId
      ) {
        const pageColorGroup = generalConfig.color.colorGroups.find((g) => g.id === pageConfig.config.colorGroupId);
        if (pageColorGroup) {
          pageConfig = {
            ...pageConfig,
            config: {
              ...pageConfig.config,
              colorGroup: pageColorGroup,
            },
          };
        }
      }

      pageConfig = {
        ...pageConfig,
        general: generalConfig,
      };
      setCurrentPageConfig(pageConfig);
    }
  };

  const isViewOnStoreApp = () => {
    let isViewOnStoreApp = window.isStoreAppWebView ?? false;
    return isViewOnStoreApp;
  };

  const handleClickOverallReview = (isShow) => {
    setIsShowOverallReview(isShow);
  };

  const getOverallReview = async () => {
    await reviewDataService.GetOverAllReviewOfBranchAsync(branchAddress?.id).then((response) => {
      if (response?.data) {
        setOverallRating(response?.data?.rating);
        setTotalReview(response?.data?.totalReview);
        setOverAllData(response?.data);
      }
    });
  };

  if (!currentPageConfig) return <></>;

  return (
    <div style={{ fontFamily: _fontFamily }}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: _fontFamily,
          },
        }}
      >
        <AddToCartToastMessage />
        <UpdateToCartToastMessage />
        <NotificationContainer />
        <ToastMessageContainer />
        {isViewOnStoreApp() === false && <PackageExpiredDialog />}
        {currentPageConfig && (
          <ThemeProvider theme={currentPageConfig}>
            <div id="main" className="main-session">
              {isViewOnStoreApp() === false ? (
                <div
                  id="header"
                  className="theme-2-header theme-2-header-overide-display"
                  onClick={() => {
                    if (clickToFocusCustomize) clickToFocusCustomize(theme2ElementCustomize.Header);
                  }}
                >
                  <div id="themeHeader">
                    <Theme2OriginalHeader
                      logo={logoDefault}
                      menuItem={
                        isCustomize
                          ? themeConfigMenu?.find((x) => x?.id === themeConfigReduxState?.general?.header?.menuId)
                              ?.onlineStoreMenuItems
                          : themeConfigReduxState?.general?.header?.menuItems
                      }
                      colorGroups={themeConfigReduxState?.general?.color?.colorGroups}
                      config={themeConfigReduxState?.general?.header}
                      isCustomize={isCustomize}
                      isDefault={isDefault}
                      stateConfig={themeConfigReduxState}
                      fontFamily={fontFamily}
                    />
                  </div>
                </div>
              ) : null}

              <ContentComponent
                {...currentPageConfig}
                {...props}
                clickToFocusCustomize={clickToFocusCustomize}
                isDefault={isDefault}
                overAllReview={
                  totalReview > 0 && (
                    <Button
                      type="ghost"
                      className="overall-review-button"
                      icon={
                        <span className="star-icon">
                          <StarIcon />
                        </span>
                      }
                      onClick={() => handleClickOverallReview(true)}
                    >
                      <span className="overall-score">{overallRating === 0 ? 5 : overallRating}</span>
                      <span className="total-review">
                        {totalReview >= 1000 ? "999+" : totalReview} {translatedData.review.toLowerCase()}
                      </span>
                    </Button>
                  )
                }
              />
            </div>
            {isViewOnStoreApp() === false ? (
              <div
                id="themeFooter"
                onClick={() => {
                  if (clickToFocusCustomize) clickToFocusCustomize(theme2ElementCustomize.Footer);
                }}
              >
                <ThemeOriginalFooter
                  menuItem={
                    themeConfigReduxState?.general?.footer?.menu?.menuItems ??
                    defaultThemePageConfig?.general?.footer?.menu?.menuItems
                  }
                  menuItemPolicy={
                    isCustomize
                      ? themeConfigMenu?.find((x) => x.id === themeConfigReduxState?.general?.footer?.policy?.menuId)
                          ?.onlineStoreMultiLevelMenus
                      : themeConfigReduxState?.general?.footer?.policy?.menuItems
                  }
                  config={themeConfigReduxState?.general}
                  colorGeneral={colorFooterGeneral}
                />
              </div>
            ) : null}
          </ThemeProvider>
        )}
      </ConfigProvider>
      <FnbReviewOfBranchModal
        open={isShowOverallReview}
        onCancel={() => handleClickOverallReview(false)}
        overAllData={overAllData}
        width={765}
        branchId={branchAddress?.id}
      />
    </div>
  );
}

export default Index;
