import { createHttp } from "../utils/http-common";

const controller = "order";

const cancelOrderAsync = (data) => {
  const http = createHttp();
  return http.put(`/${controller}/customer-cancel-order`, data);
};

const getOrderDetailByIdAsync = (
  orderId,
  branchId = "",
  isMergeCartItemsByStatus = false,
  paymentId = "",
  token = "",
  payerId = "",
) => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-order-detail-store-web?orderId=${orderId}&branchId=${branchId}&isMergeCartItemsByStatus=${isMergeCartItemsByStatus}&paymentId=${paymentId}&token=${token}&payerId=${payerId}`,
  );
};

const getOrderItemsByIdAsync = (orderId) => {
  const http = createHttp();
  return http.get(`/${controller}/get-order-items-by-order-id?orderId=${orderId}`);
};

const getReviewOrderByOrderId = (params) => {
  const http = createHttp();
  return http.get(`/${controller}/get-review-order-by-order-id`, { params });
};

const verifyProductInShoppingCartAsync = (queryString) => {
  const http = createHttp();
  return http.get(`/${controller}/verify-product-in-shopping-cart?${queryString}`);
};

const createStoreWebOrderAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/create-store-web-order`, data);
};

const mergeProductToCartItem = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/merge-order-item`, data);
};

const deleteOrderAsync = (data) => {
  const http = createHttp();
  return http.put(`/${controller}/delete-order`, data);
};

const applyDiscountCodesToOrderAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/apply-discount-codes`, data);
};

const checkProductOrComboInPlatformAsync = (queryString) => {
  const http = createHttp();
  return http.get(`/${controller}/check-product-in-platform?${queryString}`);
};

const orderDataService = {
  cancelOrderAsync,
  getOrderDetailByIdAsync,
  getReviewOrderByOrderId,
  verifyProductInShoppingCartAsync,
  createStoreWebOrderAsync,
  mergeProductToCartItem,
  deleteOrderAsync,
  getOrderItemsByIdAsync,
  applyDiscountCodesToOrderAsync,
  checkProductOrComboInPlatformAsync,
};

export default orderDataService;
