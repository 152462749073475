import { createHttp } from "../utils/http-common";

const controller = "review";

const createReviewOrder = (data) => {
  const http = createHttp();
  return http.post(`/${controller}`, data);
};

const GetOverAllReviewOfBranchAsync = (branchId = "") => {
  const http = createHttp();
  return http.get(`/${controller}/get-overall-review-of-branch?branchId=${branchId}`);
};

const GetReviewOrderAsync = (rating, branchId, pageNumber, pageSize) => {
  const http = createHttp();

  return http.get(
    `/${controller}?${
      rating ? `rating=${rating}` : ""
    }&branchId=${branchId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );
};

const reviewDataService = {
  createReviewOrder,
  GetOverAllReviewOfBranchAsync,
  GetReviewOrderAsync,
};

export default reviewDataService;
