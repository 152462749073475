import { useSelector } from "react-redux";
import styled from "styled-components";
import { BackToTopIcon } from "../../assets/icons/BackToTopIcon";
import { useEffect } from "react";

function BackToTopComponent(props) {
  const themeConfigReduxState = useSelector((state) => state?.session?.themeConfig);
  const config = themeConfigReduxState;
  const BackToTopStyled = styled.div`
    position: relative;
    display: flex;
    svg {
      margin: auto;
      padding-left: 0px;
      margin-left: 15px;
    }
  `;
  const colorGroupsDefault = config?.general?.color?.colorGroups;
  const currentColor = colorGroupsDefault?.length > 0 ? colorGroupsDefault[0] : {};

  function handleScrollBackToTop() {
    const currentScrollPos = window.scrollY;
    const topHeight = 200;
    const backToTopButtonElement = document.querySelector("#back2Top");
    if (backToTopButtonElement) {
      if (currentScrollPos - topHeight > 0) {
        backToTopButtonElement.classList.remove("d-none");
      } else {
        backToTopButtonElement.classList.add("d-none");
      }
    }
  }

  // Init scroll handler
  useEffect(() => {
    const handleScroll = () => {
      handleScrollBackToTop();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BackToTopStyled
      onClick={(e) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: adds a smooth scrolling animation
        });
      }}
      className="pointer d-none"
      id="back2Top"
    >
      <BackToTopIcon color={currentColor?.buttonTextColor} backgroundColor={currentColor?.buttonBackgroundColor} />
    </BackToTopStyled>
  );
}

export default BackToTopComponent;
