import { Button, Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "../../../../../constants/platform.constants";
import { calculatePercentageTag, formatDate, formatTextCurrency, formatTextNumber } from "../../../../../utils/helpers";
import { DeliveryHeaderIcon, InStoreIcon, TakeAwayIcon } from "../../../../assets/icons.constants";
import { IconPickupCustomize } from "../../../../assets/icons/PickupDeliveryIcon";
import productImageDefault from "../../../../assets/images/product-default-img.jpg";
import FnbDisplayImageComponent from "../../../../components/fnb-display-image/fnb-display-image.component";
import ReviewOrder from "../../../../components/review-order/review-order.component";
import { EnumOrderStatusStoreWeb, enumOrderType } from "../../../../constants/enum";
import { DateFormat } from "../../../../constants/string.constant";
import ReOrderComponent from "./components/re-order-component";
import OrderButtonComponent from "./order-button.component";
import "./order-card.component.scss";
import OrderStatusComponent from "./order-status.component";
import orderDataService from "../../../../../data-services/order-data.service";
import reviewDataService from "../../../../../data-services/review-data.service";
import FnbRate from "../../../../components/fnb-rate/fnb-rate";
import { useMediaQuery } from "react-responsive";
import { EnumReviewStatus } from "../../../../../constants/enums";

export default function OrderCardComponent(props) {
  const { data, navigateToOrderDetail, onRefesh } = props;
  const [isReOrder, setIsReOrder] = useState(false);
  const [t] = useTranslation();
  const [orderDetailData, setOrderDetailData] = useState();
  const [isLoadingReOrder, setIsLoadingReOrder] = useState(false);
  const [isQRCodeInstore, setIsQRCodeInstore] = useState(null);
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [sendingReview, setSendingReview] = useState(false);
  const [previousId, setPreviousId] = useState();
  const [reviewOrder, setReviewOrder] = useState();
  const isMobile = useMediaQuery({ maxWidth: 740 });

  const translateData = {
    items: t("orderStatus.items", "Items"),
    cash: t("paymentMethod.cash", "Cash"),
    moMo: t("paymentMethod.moMo", "MoMo"),
    atm: t("paymentMethod.atm", "ATM"),
    bankTransfer: t("paymentMethod.bankTransfer", "Bank transfer"),
    mPOS: t("paymentMethod.mPOS", "mPOS"),
    isCash: "Cash",
    isMoMo: "MoMo",
    isATM: "ATM",
    isBankTransfer: "Bank Transfer",
    isMPOS: "mPOS",
    COD: "COD",
    otherPayment: "Other Payment",
    totalAmount: t("orderStatus.totalAmount", "Total amount"),
    toPickup: t("checkOutPage.toPickup", "Tự lấy hàng"),
    delivery: t("checkOutPage.delivery", "Giao hàng"),
    inStore: t("storeWebPage.profilePage.inStore", "Note"),
    takeAway: t("storeWebPage.profilePage.takeAway", "Note"),
    onlineDelivery: t("storeWebPage.profilePage.onlineDelivery", "Note"),
    platform: t("storeWebPage.profilePage.platform", "Platform"),
    canceledByCashier: t("storeWebPage.profilePage.cancelByCashier", "Đã hủy bởi thu ngân"),
    errorOccurredDuringProcessing: t("messages.errorOccurredDuringProcessing"),
    thanksForYourRating: t("storeWebPage.generalUse.thanksForYourRating"),
    review: t("review.review", "Đánh giá"),
    reviewOrder: t("review.reviewOrder", "Đánh giá đơn hàng"),
  };
  const firstOrderItem = data?.orderItems[0];
  useEffect(() => {
    if (
      data?.orderTypeId == 0 &&
      (data?.platformId?.toLowerCase() === Platform.StoreWebsite.toLowerCase() ||
        data?.platformId?.toLowerCase() === Platform.StoreMobileApp.toLowerCase())
    ) {
      setIsQRCodeInstore(true);
    } else {
      setIsQRCodeInstore(false);
    }
  }, [data]);

  const translatePayment = (data) => {
    switch (data.toLowerCase()) {
      case translateData.isCash.toLowerCase():
        return translateData.cash;
      case translateData.COD.toLowerCase():
        return translateData.COD;
      case translateData.isMoMo.toLowerCase():
        return translateData.moMo;
      case translateData.isBankTransfer.toLowerCase():
        return translateData.bankTransfer;
      case translateData.isMPOS.toLowerCase():
        return translateData.mPOS;
      default:
        return translateData.atm;
    }
  };

  const renderToppings = (toppings) => {
    if (!toppings) {
      return <></>;
    }

    let render = toppings?.map((topping) => (
      <>
        <Col xs={11} sm={13} className="topping topping-name">
          {topping?.toppingName}
        </Col>
        <Col xs={3} sm={2} className="topping topping-quantity">
          x{topping?.quantity}
        </Col>
        <Col xs={10} sm={9} className="topping topping-price">
          <div className="topping-price-col">
            <span className="topping-price-value">{formatTextCurrency(topping?.priceAfterDiscount)}</span>
            {topping?.priceAfterDiscount < topping?.originalPrice && (
              <span className="topping-original-price">{formatTextCurrency(topping?.originalPrice)}</span>
            )}
          </div>
        </Col>
      </>
    ));
    return render;
  };

  const renderOptions = (options) => {
    if (!options) {
      return <></>;
    }

    let render = options?.map((option) => option?.optionName + ` (${option?.optionLevelName})`);
    render = render.toString().replace(",", ", ");
    return (
      <>
        <Col xs={24} className="option option-name">
          {render}
        </Col>
      </>
    );
  };

  const renderCombo = (comboItems) => {
    let render = comboItems?.map((item) => (
      <>
        <Col xs={24} className="combo-item-name">
          {item?.fullName}
        </Col>
        {renderOptions(item?.orderItemOptions)}
        {renderToppings(item?.orderItemToppings)}
      </>
    ));
    return render;
  };

  const showReviewOrder = async (orderId) => {
    if (previousId !== orderId) {
      setLoadingReview(true);
      setPreviousId(orderId);
      await orderDataService
        .getReviewOrderByOrderId({ orderId })
        .then((res) => {
          if (res?.data && res?.data?.isSuccess) {
            const { order } = res?.data;
            setReviewOrder(order);
            setIsOpenReview(true);
          } else {
            message.error(translateData.errorOccurredDuringProcessing);
          }
          setLoadingReview(false);
        })
        .catch(() => {
          setLoadingReview(false);
          message.error(translateData.errorOccurredDuringProcessing);
        });
    } else {
      setIsOpenReview(true);
    }
  };

  const hiddenReviewOrder = () => {
    setIsOpenReview(false);
  };

  const handleSendReview = async (values) => {
    setSendingReview(true);
    await reviewDataService
      .createReviewOrder(values)
      .then((res) => {
        if (res?.data && res?.data?.succeeded) {
          onRefesh();
          setIsOpenReview(false);
          message.info(translateData.thanksForYourRating);
        } else {
          message.error(translateData.errorOccurredDuringProcessing);
        }
        setSendingReview(false);
      })
      .catch(() => {
        setSendingReview(false);
        message.error(translateData.errorOccurredDuringProcessing);
      });
  };

  const renderReview = (data) => {
    return (
      <>
        {data?.statusId === EnumOrderStatusStoreWeb.Completed && !data?.review && (
          <Button className={"btn-review-order"} onClick={() => showReviewOrder(data?.id)} loading={loadingReview}>
            {translateData.review}
          </Button>
        )}
        {data?.statusId === EnumOrderStatusStoreWeb.Completed &&
          data?.review &&
          data?.review?.status === EnumReviewStatus.Show && (
            <div className="content-review">
              <div className="content-review-label">{`${translateData.reviewOrder}:`}</div>
              <FnbRate defaultValue={data?.review?.rating} size={isMobile ? 15 : 20} disabled={true} />
            </div>
          )}
      </>
    );
  };

  return (
    <div className="order-card">
      <Row className="order-card-header">
        <Col xs={24} sm={12} className="order-card-header-left">
          <div className="icon">
            {data?.orderTypeId === enumOrderType.DELIVERY && <DeliveryHeaderIcon />}
            {data?.orderTypeId === enumOrderType.PICK_UP && <IconPickupCustomize />}
            {data?.orderTypeId === enumOrderType.INSTORE && <InStoreIcon />}
            {data?.orderTypeId === enumOrderType.TAKE_AWAY && <TakeAwayIcon />}
          </div>
          <div className="order-info">
            <div>
              <span className="my-order-delivery-name" onClick={() => navigateToOrderDetail(data?.id)}>
                {data?.orderTypeId === enumOrderType.DELIVERY && translateData.delivery}
                {data?.orderTypeId === enumOrderType.PICK_UP && translateData.toPickup}
                {data?.orderTypeId === enumOrderType.INSTORE && translateData.inStore}
                {data?.orderTypeId === enumOrderType.TAKE_AWAY && translateData.takeAway}
              </span>
              <span className="my-order-code" onClick={() => navigateToOrderDetail(data?.id)}>
                #{data?.stringCode}
              </span>
            </div>
            <span className="my-order-status">
              <OrderStatusComponent statusId={data?.statusId} />
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} className="order-card-header-right">
          <div className="order-info">
            <span className="platform">{data?.platform}</span>
            <span className="time-order">{formatDate(data?.createdTime, DateFormat.HH_MM__DD_MM_YYYY_)}</span>
          </div>
        </Col>
      </Row>
      <div className="order-card-content">
        <div className="order-item-image">
          <FnbDisplayImageComponent src={firstOrderItem?.thumbnail} srcFallback={productImageDefault} />
        </div>
        <div className="order-item-content">
          <Row className="order-item-info">
            <Col xs={24} sm={13} className="order-item-name">
              <div className="mobile-order-item-image">
                <FnbDisplayImageComponent src={firstOrderItem?.thumbnail} srcFallback={productImageDefault} />
              </div>
              <div className="mobile-order-item-name">{firstOrderItem?.name}</div>
            </Col>
            <Col xs={12} sm={2} className="order-item-quantity">
              {firstOrderItem?.quantity}
            </Col>
            <Col xs={12} sm={9} className="order-item-price">
              <div>
                <span className="selling-price">{formatTextCurrency(firstOrderItem?.totalPriceAfterDiscount)}</span>
                {firstOrderItem?.priceAfterDiscount < firstOrderItem?.originalPrice && (
                  <div className="original-price--percent-discount">
                    <span className="original-price">
                      {formatTextCurrency(firstOrderItem?.originalPrice * firstOrderItem?.quantity)}
                    </span>
                    {firstOrderItem?.originalPrice !== 0 && (
                      <div className="percent-discount">
                        <span>
                          {calculatePercentageTag(firstOrderItem?.priceAfterDiscount, firstOrderItem?.originalPrice)}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
            {firstOrderItem?.isCombo ? (
              <>{renderCombo(firstOrderItem?.orderComboItem?.orderComboProductPriceItems)}</>
            ) : (
              <>
                {renderOptions(firstOrderItem?.orderItemOptions)}
                {renderToppings(firstOrderItem?.orderItemToppings)}
              </>
            )}
          </Row>
        </div>
      </div>
      {data?.statusId === EnumOrderStatusStoreWeb.ToConfirm ||
      data?.statusId === EnumOrderStatusStoreWeb.Completed ||
      data?.statusId === EnumOrderStatusStoreWeb.Canceled ? (
        <>
          <Row className="order-card-total">
            <Col xs={24} sm={12} className="total-order-items">
              {data?.orderItems?.length} {translateData.items} - {translatePayment(data?.paymentMethod)}
            </Col>
            <Col xs={24} sm={12} className="total-price">
              {translateData.totalAmount}: <div className="total-amount">{formatTextCurrency(data?.totalPrices)}</div>
            </Col>
          </Row>
          {!isQRCodeInstore ? (
            <div className="order-card-footer">
              <div className={`order-card-footer-btn ${data?.review && `footer-review`}`}>
                {renderReview(data)}
                <OrderButtonComponent
                  statusId={data?.statusId}
                  data={data}
                  onClick={() => {
                    setIsReOrder(true);
                    setOrderDetailData(data);
                    setIsLoadingReOrder(true);
                  }}
                  isLoading={isLoadingReOrder}
                  orderId={data?.id}
                  onRefesh={onRefesh}
                  phoneNumber={data?.storeBranchPhoneNumber}
                />
              </div>
              {data?.cancelByCashier && (
                <div className="order-cancel-by-cashier">
                  <span>{translateData.canceledByCashier}</span>
                </div>
              )}
            </div>
          ) : (
            <div className="order-card-footer">
              <div className={`order-card-footer-btn ${data?.review && `footer-review`}`}>{renderReview(data)}</div>
            </div>
          )}
        </>
      ) : (
        <>
          <Row className="order-card-total border-radius-bottom">
            <Col xs={24} sm={12} className="total-order-items">
              {data?.orderItems?.length} {translateData.items} - {translatePayment(data?.paymentMethod)}
            </Col>
            <Col xs={24} sm={12} className="total-price">
              {translateData.totalAmount}: <div className="total-amount">{formatTextCurrency(data?.totalPrices)}</div>
            </Col>
          </Row>
        </>
      )}
      {isReOrder ? (
        <ReOrderComponent
          orderDetailData={orderDetailData}
          onClosed={() => {
            setIsLoadingReOrder(false);
            setIsReOrder(false);
          }}
          t={t}
        />
      ) : null}
      <ReviewOrder
        open={isOpenReview}
        onOk={handleSendReview}
        onCancel={hiddenReviewOrder}
        loading={sendingReview}
        data={reviewOrder}
      />
    </div>
  );
}
