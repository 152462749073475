import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationDetail: undefined,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification(state, action) {
      state.notificationDetail = action.payload;
    },
    resetNotification(state, action) {
      state.notificationDetail = undefined;
    },
  },
});

export const notificationActions = notificationSlice.actions;

export const notificationSelector = (state) => state.notification;

export const notificationDetailSelector = (state) => state.notification?.notificationDetail;

const notificationReducer = notificationSlice.reducer;

export default notificationReducer;
