import { Card, Col, Drawer, Image, Modal, Row } from "antd";
import "./fnb-review-of-branch-modal.scss";
import FnbReview from "../fnb-review/fnb-review";
import { useTranslation } from "react-i18next";
import FnbRate from "../fnb-rate/fnb-rate";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import noReviewComment from "../../assets/images/no-review-comment.png";
import InfiniteScroll from "react-infinite-scroll-component";
import reviewDataService from "../../../data-services/review-data.service";
import { DateFormat } from "../../constants/string.constant";
import moment from "moment";

const FnbReviewOfBranchModal = (props) => {
  const { open, onCancel, centered, title, className, width, overAllData, branchId, ...rest } = props;
  const [t] = useTranslation();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [ratingFilter, setRatingFilter] = useState();
  const [listReviewData, setListReviewData] = useState([]);
  const [totalReview, setTotalReview] = useState(0);
  const pageSize = 20;
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  const translatedData = {
    reviewAndComment: t("review.reviewAndComment"),
    review: t("review.review"),
    reviewOrder: t("review.reviewOrder"),
    noReviewMessage: t("review.noReviewMessage"),
  };

  useEffect(() => {
    if (open) {
      InitData();
    }
  }, [open]);

  const InitData = async () => {
    setPageNumber(1);
    setHasMoreData(true);
    setRatingFilter("");
    await reviewDataService.GetReviewOrderAsync("", branchId, 1, pageSize).then((res) => {
      if (res?.data) {
        setListReviewData(res?.data?.data?.datas);
        setTotalReview(res?.data?.data?.total);
      }
    });
  };

  const fetchMoreData = () => {
    if (listReviewData.length >= totalReview) {
      setHasMoreData(false);
      return;
    }

    reviewDataService.GetReviewOrderAsync(ratingFilter, branchId, pageNumber + 1, pageSize).then((res) => {
      if (res?.data) {
        setTimeout(() => {
          var newData = listReviewData.concat(res?.data?.data?.datas);
          setListReviewData(newData);
          setPageNumber(pageNumber + 1);
        }, 500);
      }
    });
  };

  const onchangeRatingFilter = async (rating) => {
    setRatingFilter(rating?.target?.value);
    await reviewDataService.GetReviewOrderAsync(rating?.target?.value, branchId, 1, pageSize).then((res) => {
      if (res?.data) {
        if (res?.data?.data?.datas?.length === 0) {
          setHasMoreData(false);
          setListReviewData([]);
          return;
        }
        setListReviewData(res?.data?.data?.datas);
        setHasMoreData(true);
        setPageNumber(1);
        setTotalReview(res?.data?.data?.total);
      }
    });
  };

  return (
    <>
      {isMaxWidth575 ? (
        <Drawer
          placement="bottom"
          title={title ?? translatedData.reviewAndComment}
          open={open}
          onClose={() => {
            onCancel();
          }}
          forceRender={true}
          zIndex={1001}
          closable
          height={"95dvh"}
          className="review-comment-drawer"
          destroyOnClose
          {...rest}
        >
          {overAllData?.totalReview > 0 ? (
            <>
              <Card className="overall-content-container">
                <Row className="overall-content" gutter={[12, 8]}>
                  <Col className="overall-rating-container">
                    <div className="overall-score">{overAllData?.rating === 0 ? 5 : overAllData?.rating}</div>
                    <FnbRate
                      className="overall-star-rate"
                      defaultValue={overAllData?.rating === 0 ? 5 : Math.round(overAllData?.rating)}
                      disabled={true}
                    ></FnbRate>
                    <p className="total-review">
                      {overAllData?.totalReview >= 1000 ? "999+" : overAllData?.totalReview}{" "}
                      {translatedData.review.toLowerCase()}
                    </p>
                  </Col>
                  <Col className="rating-percent-container">
                    <FnbReview
                      className="rating-percent"
                      data={overAllData?.listReviewStar}
                      onChange={onchangeRatingFilter}
                    ></FnbReview>
                  </Col>
                </Row>
              </Card>
              <div className="detail-content-container">
                <div className="order-review-title">{translatedData.reviewOrder}</div>
                <div className="order-review-list">
                  <InfiniteScroll
                    dataLength={listReviewData?.length}
                    next={fetchMoreData}
                    hasMore={hasMoreData}
                    height={"43dvh"}
                    scrollThreshold={1}
                  >
                    {listReviewData?.map((review, index) => (
                      <div className="order-review-container" key={index}>
                        <div className="customer-image">
                          <Image
                            preview={false}
                            src={review?.customer?.thumbnail ?? "/images/default-theme/avatar-profile-default.png"}
                          />
                        </div>
                        <div className="customer-review">
                          <div className="customer-name">{review?.customer?.fullName}</div>
                          <div>
                            <FnbRate className="rating" disabled={true} value={review?.rating} />
                          </div>
                          <div className="time">
                            {moment.utc(review?.createdTime).local().locale("vi").format(DateFormat.HH_MM)}{" "}
                            {moment(review?.createdTime).format(DateFormat.DD_MM_YYYY)}
                          </div>
                          <p className="comment">{review?.comment}</p>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image width={176} height={180} preview={false} src={noReviewComment} />
                <div style={{ width: "236px", textAlign: "center" }}>{translatedData.noReviewMessage}</div>
              </div>
            </>
          )}
        </Drawer>
      ) : (
        <Modal
          open={open}
          onCancel={onCancel}
          closable={true}
          centered={true}
          transitionName={null}
          title={title ?? translatedData.reviewAndComment}
          footer={null}
          className={`review-comment-modal ${className}`}
          wrapClassName="popover-wrapper-overwrite"
          width={width || "50dvw"}
          destroyOnClose
          onClose={() => {
            onCancel();
          }}
          {...rest}
        >
          {overAllData?.totalReview > 0 ? (
            <>
              <Card className="overall-content-container">
                <Row gutter={[24, 8]}>
                  <Col className="overall-rating-container">
                    <div className="overall-score">{overAllData?.rating === 0 ? 5 : overAllData?.rating}</div>
                    <FnbRate
                      className="overall-star-rate"
                      defaultValue={overAllData?.rating === 0 ? 5 : Math.round(overAllData?.rating)}
                      disabled={true}
                    ></FnbRate>
                    <p className="total-review">
                      {overAllData?.totalReview >= 1000 ? "999+" : overAllData?.totalReview}{" "}
                      {translatedData.review.toLowerCase()}
                    </p>
                  </Col>
                  <Col>
                    <FnbReview data={overAllData?.listReviewStar} onChange={onchangeRatingFilter}></FnbReview>
                  </Col>
                </Row>
              </Card>
              <div className="detail-content-container">
                <div className="order-review-title">{translatedData.reviewOrder}</div>
                <div className="order-review-list">
                  <InfiniteScroll
                    dataLength={listReviewData?.length}
                    next={fetchMoreData}
                    hasMore={hasMoreData}
                    height={"42dvh"}
                    scrollThreshold={1}
                  >
                    {listReviewData?.map((review, index) => (
                      <div className="order-review-container" key={index}>
                        <div className="customer-image">
                          <Image
                            preview={false}
                            src={review?.customer?.thumbnail ?? "/images/default-theme/avatar-profile-default.png"}
                          />
                        </div>
                        <div className="customer-review">
                          <div className="customer-name">{review?.customer?.fullName}</div>
                          <div>
                            <FnbRate className="rating" disabled={true} value={review?.rating} />
                          </div>
                          <div className="time">
                            {moment.utc(review?.createdTime).local().locale("vi").format(DateFormat.HH_MM_DD_MM_YYYY_)}
                          </div>
                          <p className="comment">{review?.comment}</p>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image width={176} height={180} preview={false} src={noReviewComment} />
              <div style={{ width: "236px", textAlign: "center" }}>{translatedData.noReviewMessage}</div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default FnbReviewOfBranchModal;
